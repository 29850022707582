var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":_vm.closePasswordDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline white"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.changePassword'))+" "),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.closePasswordDialog}},[_vm._v(" mdi-close ")])],1),(!_vm.accessPassword.length)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('v-card-text',[_c('v-container',[_c('validation-provider',{attrs:{"rules":{required: true},"name":_vm.$vuetify.lang.t('$vuetify.oldPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":_vm.$vuetify.lang.t('$vuetify.oldPassword'),"prepend-icon":"mdi-lock","type":_vm.showOldPassword ? 'text' : 'password',"append-icon":_vm.showOldPassword ? 'mdi-eye-off' : 'mdi-eye',"error-messages":errors},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required: true},"name":_vm.$vuetify.lang.t('$vuetify.newPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":_vm.$vuetify.lang.t('$vuetify.newPassword'),"prepend-icon":"mdi-lock-reset","type":_vm.showNewPassword ? 'text' : 'password',"append-icon":_vm.showNewPassword ? 'mdi-eye-off' : 'mdi-eye',"error-messages":errors},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"px-6 pb-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","text":""},on:{"click":_vm.closePasswordDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")]),_c('v-btn',{key:"changePassword",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":!_vm.validPassword}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.changePassword'))+" ")])],1)],1)]}}],null,false,1787070581)}):_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.loginWithAccessPassword)}}},[_c('v-card-text',[_c('v-container',[_c('validation-provider',{attrs:{"rules":{required: true},"name":_vm.$vuetify.lang.t('$vuetify.oldPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Senha de acesso temporária","prepend-icon":"mdi-lock","type":_vm.showOldPassword ? 'text' : 'password',"append-icon":_vm.showOldPassword ? 'mdi-eye-off' : 'mdi-eye',"error-messages":errors},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.accessPassword),callback:function ($$v) {_vm.accessPassword=$$v},expression:"accessPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required: true},"name":_vm.$vuetify.lang.t('$vuetify.newPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":_vm.$vuetify.lang.t('$vuetify.newPassword'),"prepend-icon":"mdi-lock-reset","type":_vm.showNewPassword ? 'text' : 'password',"append-icon":_vm.showNewPassword ? 'mdi-eye-off' : 'mdi-eye',"error-messages":errors},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"px-6 pb-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","text":""},on:{"click":_vm.closePasswordDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")]),_c('v-btn',{key:"changePassword",attrs:{"type":"submit","color":"primary","loading":_vm.loadingChangePassword,"disabled":!_vm.validPassword}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.changePassword'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }